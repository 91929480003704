import React from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';


const  CardBlog = ({data}) => {
   
        console.log(data)
   
    
  return (
      <a href={data.url}>
       <div className={'block bloqueBlog mt-4 mb-5 mx-3 heightCard'} style={{flex:'unset'}}>
           <div>
                <LazyLoadImage className={'heightImagenCardBlog'} src={data.imagen} style={{borderRadius: '20px 20px 0px 0px',width:'100%',objectFit:'cover'}} />
           </div>
           <div className={'my-3 mx-4 heightDescription'} >
                <div style={{marginBottom:'16px'}}>
                <h2 className={'tituloCardBlog'} /*style={{height:'115px'}}*/>{data.titulo}</h2>
                </div>
               <div style={{marginBottom:'32px'}}>
               <p className="labelNormal ocultar">{data.resumen_card}</p>
               </div>
               
           </div>
           <div className={'text-center'}>
                    <a className={'btn-tex-mob-sb links'} href={data.url}>Leer mas</a>
               </div>
       </div>
       </a>
    
  );
};

export default CardBlog;
